// @ts-nocheck
/**
 * @typedef {ReturnType<typeof import('widgets/global/Carousel').default>} BaseCarousel
 */

import { PausableTimeout } from 'widgets/toolbox/PausableTimeout';
import { timeout } from 'widgets/toolbox/util';

const DIRECTION_HORIZONTAL = 'horizontal';
const FOCUSABLE_SELECTOR = 'a, button';

/**
 * @param {number[]} list list of values
 * @param {number} value value to find
 * @returns {number} index of closest list item to provided value
 */
export function getClosestIndex(list, value) {
    var index = 0;
    var diff = Math.abs(value - list[0]);

    for (let i = 1; i < list.length; i++) {
        const newdiff = Math.abs(value - list[i]);

        if (newdiff < diff) {
            diff = newdiff;
            index = i;
        }
    }

    return index;
}

/**
 * @param {BaseCarousel} BaseCarousel Base widget for extending
 * @returns {typeof Carousel} Carousel class
 */

export default function (BaseCarousel) {
    /**
     * @category widgets
     * @subcategory global
     * @class Carousel
     * @augments BaseCarousel
     * @classdesc Represents Carousel component with next features:
     * @property {string} data-autoplay-infinite
 */

    class Carousel extends BaseCarousel {
        prefs() {
            return {
                autoplayInfinite: false,
                autoplayEnabled: false,
                classesNoAnimate: 'm-no_animate',
                addExternalSlides: false,
                addExternalSlidesPosition: 'start',
                itemsPerSlide: false,
                calculateTileWidth: false,
                gsaModuleCssClass: '.m-alternative_category_tiles',
                scrollToTop: false,
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization
     */
        init() {
            if (!this.isExternalSlidesAdded()) {
                this.beforeInit();
            }

            super.init();

            // @ts-ignore
            if (this.prefs().isProductsCarousel) {
                this.eventBus().emit('carousel.loaded');
            }

            const gsaContainer = this.ref('self').get().closest(this.prefs().gsaModuleCssClass);

            if (this.prefs().calculateTileWidth || gsaContainer) {
                setTimeout(this.categoryTileWidth, 200);
            }
        }

        /**
         * @description Initial carousel configuration
         */
        initCarousel() {
            super.initCarousel();

            const elCarouselTrack = this.ref('elemCarouselTrack').get();

            if (!elCarouselTrack) {
                return;
            }

            this.slidesCount = elCarouselTrack.children.length;
            this.slidesCloned = 0;

            if (this.prefs().autoplayInfinite) {
                this.initInfinite();
            }

            if (this.prefs().autoplayEnabled) {
                this.initAutoplay();
            }

            this.markVisibleElements();
        }

        /**
         * @description Add external slides before init
         */
        beforeInit() {
            const elCarouselTrack = this.ref('elemCarouselTrack').get();
            const isExternalSlides = this.prefs().addExternalSlides;

            if (!elCarouselTrack || !isExternalSlides) {
                return;
            }

            const externalSlides = document.getElementById(isExternalSlides);

            if (this.prefs().addExternalSlidesPosition === 'start') {
                elCarouselTrack.prepend(externalSlides.content.cloneNode(true));
            } else {
                elCarouselTrack.append(externalSlides.content.cloneNode(true));
            }
        }

        onRefresh() {
            super.onRefresh();

            this.currentPage = 0;

            timeout(() => {
                if (this.prefs().autoplayInfinite) {
                    this.initInfinite();
                }

                if (this.prefs().autoplayEnabled) {
                    this.initAutoplay();
                }
            }); // timeout to postpone initialisation until all references will be refreshed
        }

        /**
         * @description Viewtype change event handler.
         * Recalculates carousel metrics and redefines carousel states.
         */
        onViewtypeChange() {
            super.onViewtypeChange();

            this.updateCarouselMetric();
            this.updateCarouselState();
        }

        initInfinite() {
            const elCarouselTrack = this.ref('elemCarouselTrack').get();

            if (!elCarouselTrack || elCarouselTrack.clientWidth >= elCarouselTrack.scrollWidth) {
                return;
            }

            this.ref('elemCarouselTrack').addClass(this.prefs().classesNoAnimate);

            this.slidesCloned = this.getSlidesPerPage();

            /** @type {Node[]} */
            const appendSlides = [];
            /** @type {Node[]} */
            const prependSlides = [];

            /**
             * @param {Element} node item
             * @returns {Node} clonedNode
             */
            const getClonedNode = (node) => {
                const clonedNode = node.cloneNode(true);

                clonedNode.dataset.cloned = true;
                Array.from(clonedNode.querySelectorAll(FOCUSABLE_SELECTOR)).forEach(el => el.setAttribute('tabindex', '-1'));

                return clonedNode;
            };

            // Add slides at the end
            for (let i = 0; i < this.slidesCloned; i++) {
                appendSlides.push(getClonedNode(elCarouselTrack.children[i]));
            }

            // Add slides to the beginning
            for (let i = this.slidesCount - 1; i > this.slidesCount - 1 - this.slidesCloned; i--) {
                prependSlides.push(getClonedNode(elCarouselTrack.children[i]));
            }

            appendSlides.forEach(clonedSlide => elCarouselTrack.appendChild(clonedSlide));
            prependSlides.forEach(clonedSlide => elCarouselTrack.prepend(clonedSlide));

            // Scroll to the first page after adding cloned slides
            this.scrollWithoutAnimate(0);

            this.onDestroy(() => {
                Array.from(elCarouselTrack.children).forEach(elSlide => {
                    if (elSlide.dataset.cloned) {
                        elSlide.remove();
                    }
                });
            });
        }

        initAutoplay() {
            const elCarouselTrack = this.ref('elemCarouselTrack').get();

            if (!elCarouselTrack) {
                return;
            }

            const elSelf = this.ref('self').get();

            const autoplayClear = () => {
                if (this.pausableTimeout) {
                    this.pausableTimeout.destroy();
                }
            };

            const autoplayPause = () => {
                if (this.pausableTimeout) {
                    this.pausableTimeout.pause();
                }
            };

            const autoplayResume = () => {
                if (this.pausableTimeout) {
                    this.pausableTimeout.resume();
                }
            };

            const autoplay = () => {
                autoplayClear();
                this.pausableTimeout = new PausableTimeout(() => {
                    const lastPageIndex = Math.round(elCarouselTrack.scrollWidth / elCarouselTrack.clientWidth) - 1;
                    const currentPageIndex = this.callIfExists('getCurrentPageIndex');
                    var nextPageIndex = 0;

                    if (currentPageIndex < lastPageIndex) {
                        nextPageIndex = currentPageIndex + 1;
                    }

                    this.callIfExists('scrollToPage', nextPageIndex);
                    autoplay();
                }, this.prefs().autoplayDuration);
            };

            autoplay();

            this.onDestroy(autoplayClear);

            this.ev('mouseenter', () => autoplayPause(), elSelf);
            this.ev('mouseleave', () => autoplayResume(), elSelf);

            this.ev('focus', () => autoplayPause(), FOCUSABLE_SELECTOR);
            this.ev('blur', () => autoplayResume(), FOCUSABLE_SELECTOR);
        }

        /**
         * @description Defines if carousel direction is horizontal
         *
         * @returns {boolean} true if carousel direction is horizontal
         */
        isHorizontal() {
            return this.carouselDirection === DIRECTION_HORIZONTAL;
        }

        /**
         * @description Get current page index
         *
         * @returns {number} index of carrent page
         */
        getCurrentPageIndex() {
            var currentPageIndex = super.getCurrentPageIndex() - (this.prefs().autoplayInfinite ? 1 : 0);
            const numberOfPages = this.getPagesCount();

            if (currentPageIndex < 0) {
                currentPageIndex = (currentPageIndex % numberOfPages) + numberOfPages;
            } else if (currentPageIndex >= numberOfPages) {
                currentPageIndex %= numberOfPages;
            }

            return currentPageIndex;
        }

        /**
         * @description Get number of carousel pages
         *
         * @returns {number} number of pages
         */
        getSlidesPerPage() {
            var slidesPerPage = 1;
            const elCarouselTrack = this.ref('elemCarouselTrack').get();
            const elCarouselChild = elCarouselTrack.children[0];
            const elCarouselTrackRect = elCarouselTrack.getBoundingClientRect();

            if (elCarouselTrack && elCarouselChild) {
                if (this.isHorizontal()) {
                    slidesPerPage = Math.floor(elCarouselTrackRect.width / elCarouselChild.getBoundingClientRect().width);
                } else {
                    slidesPerPage = Math.floor(elCarouselTrackRect.height / elCarouselChild.getBoundingClientRect().height);
                }
            }

            return slidesPerPage;
        }

        /**
         * @description Get number of carousel pages
         *
         * @returns {number} number of pages
         */
        getPagesCount() {
            var numberOfPages = 1;
            const elCarouselTrack = this.ref('elemCarouselTrack').get();

            if (elCarouselTrack) {
                if (this.slidesCount) {
                    numberOfPages = Math.ceil(this.slidesCount / this.getSlidesPerPage());
                } else {
                    numberOfPages = Math.ceil(elCarouselTrack.scrollWidth / elCarouselTrack.clientWidth);
                }
            }

            return numberOfPages;
        }

        /**
         * @description Scroll carousel to specific position without animate
         * @param {any} pageIndex - page index
         *
         * @returns {this} carousel instance
         */
        scrollWithoutAnimate(pageIndex) {
            const index = pageIndex + (this.prefs().autoplayInfinite ? 1 : 0);
            const elCarouselTrack = this.ref('elemCarouselTrack').get();

            if (!elCarouselTrack) {
                return this;
            }

            if (this.isHorizontal()) {
                elCarouselTrack.scrollLeft = Math.round(elCarouselTrack.clientWidth * index);
            } else {
                elCarouselTrack.scrollTop = Math.round(elCarouselTrack.clientHeight * index);
            }

            return this;
        }

        /**
         * @description Scroll carousel to specific page
         * @param {any} pageIndex - page index
         *
         * @returns {this} carousel instance
         */
        scrollToPage(pageIndex) {
            let index = pageIndex;

            if (this.scrolling) {
                return this;
            }

            if (this.prefs().autoplayInfinite) {
                const numberOfPages = this.getPagesCount();
                const slidesPerPage = this.getSlidesPerPage();

                if (index >= numberOfPages) {
                    // If switch from last to first
                    this.scrollToSlideWithoutAnimate(
                        this.slidesCloned - ((numberOfPages * slidesPerPage) - this.slidesCount) - 1
                    );
                    index = 0;
                } else if (index < 0) {
                    // If switch from first to last
                    this.scrollWithoutAnimate(numberOfPages);
                    index = numberOfPages - 1;
                }

                index += 1;
            }

            return super.scrollToPage(index);
        }

        /**
         * @description Get current page index
         *
         * @returns {number} index of carrent page
         */
        getCurrentSlideIndex() {
            const elCarouselTrack = this.ref('elemCarouselTrack').get();
            let slideIndex = 0;

            if (elCarouselTrack) {
                if (this.isHorizontal()) {
                    slideIndex = getClosestIndex(
                        Array.from(elCarouselTrack.children).map(element => element.offsetLeft),
                        Math.round(elCarouselTrack.scrollLeft)
                    );
                } else {
                    slideIndex = getClosestIndex(
                        Array.from(elCarouselTrack.children).map(element => element.offsetTop),
                        Math.round(elCarouselTrack.scrollTop)
                    );
                }
            }

            slideIndex -= this.slidesCloned;

            if (slideIndex < 0) {
                slideIndex = (slideIndex % this.slidesCount) + this.slidesCount;
            } else if (slideIndex >= this.slidesCount) {
                slideIndex %= this.slidesCount;
            }

            return slideIndex;
        }

        /**
         * @description Scroll carousel to specific slide without animate
         * @param {any} slideIndex - slide index
         * @param {boolean} skipCloned - skip cloned slides
         *
         * @returns {this} carousel instance
         */
        scrollToSlideWithoutAnimate(slideIndex, skipCloned = false) {
            const elCarouselTrack = this.ref('elemCarouselTrack').get();

            if (!elCarouselTrack) {
                return this;
            }

            let scrollIndex = slideIndex;

            if (skipCloned) {
                scrollIndex += this.slidesCloned;
            }

            const elCarouselSlide = elCarouselTrack.children[scrollIndex];

            if (!elCarouselSlide) {
                return this;
            }

            if (this.isHorizontal()) {
                elCarouselTrack.scrollLeft = elCarouselSlide.offsetLeft;
            } else {
                elCarouselTrack.scrollTop = elCarouselSlide.offsetTop;
            }

            return this;
        }

        /**
         * @description Scroll carousel to specific slide
         * @param {number} slideIndex - slide index
         *
         * @returns {Carousel} carousel instance
         */
        scrollToSlide(slideIndex) {
            const elCarouselTrack = this.ref('elemCarouselTrack').get();

            if (!elCarouselTrack || this.scrolling) {
                return this;
            }

            if (this.prefs().autoplayInfinite && this.slidesCount) {
                if (slideIndex + this.slidesCloned >= this.slidesCount + this.slidesCloned) {
                    // If switch from last to first
                    this.scrollToSlideWithoutAnimate(this.slidesCloned - 1);
                    slideIndex = 0;
                } else if (slideIndex < 0) {
                    // If switch from first to last
                    this.scrollWithoutAnimate(this.slidesCount + this.slidesCloned);
                    slideIndex = this.slidesCount - 1;
                }
            }

            const elCarouselSlide = elCarouselTrack.children[slideIndex + this.slidesCloned];

            if (!elCarouselSlide) {
                return this;
            }

            if (this.isHorizontal()) {
                timeout(() => this.scrollTo(0, elCarouselSlide.offsetLeft));
            } else {
                timeout(() => this.scrollTo(elCarouselSlide.offsetTop, 0));
            }

            return this;
        }

        /**
         * @description Scroll carousel to next slide
         * @param {RefElement} [el] source of event
         * @returns {Carousel} carousel instance
         */
        scrollToNextSlide(el) {
            if (el && el.data('globalEventClick')) {
                this.eventBus().emit(el.data('globalEventClick'), el);
            }

            return this.scrollToSlide(this.getCurrentSlideIndex() + 1);
        }

        /**
         * @description Scroll carousel to previous slide
         * @param {RefElement} [el] source of event
         * @returns {Carousel} carousel instance
         */
        scrollToPrevSlide(el) {
            if (el && el.data('globalEventClick')) {
                this.eventBus().emit(el.data('globalEventClick'), el);
            }

            return this.scrollToSlide(this.getCurrentSlideIndex() - 1);
        }

        /**
         * @description Scroll carousel to the next page
         * @listens dom#click
         */
        scrollToNextPage() {
            if (this.prefs().itemsPerSlide || this.ref('self').get().classList.contains('b-category_tile')) {
                const perSlide = this.prefs().itemsPerSlide || 1;

                this.scrollToSlide(this.getCurrentSlideIndex() + perSlide);
            } else {
                const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();

                if (this.carouselDirection === 'horizontal') {
                    elemCarouselTrack?.scrollBy(this.carouselDimension, 0);
                } else {
                    elemCarouselTrack?.scrollBy(0, this.carouselDimension);
                }
            }
        }

        /**
         * @description Scroll carousel to the previous page
         * @listens dom#click
         */
        scrollToPrevPage() {
            if (this.prefs().itemsPerSlide || this.ref('self').get().classList.contains('b-category_tile')) {
                const perSlide = this.prefs().itemsPerSlide || 1;

                this.scrollToSlide(this.getCurrentSlideIndex() - perSlide);
            } else {
                const elemCarouselTrack = this.ref(this.prefs().elemCarouselTrack).get();
                const scrollTo = this.prefs().scrollToTop ? -9999 : -this.carouselDimension;

                if (this.carouselDirection === 'horizontal') {
                    elemCarouselTrack?.scrollBy(-this.carouselDimension, 0);
                } else {
                    elemCarouselTrack?.scrollBy(0, scrollTo);
                }
            }
        }

        /**
         * @description Update carousel during scroll
         */
        updateDuringScroll() {
            this.updateCarouselState();

            if (this.pagination) {
                this.setActivePagination();
            } else {
                this.initPagination();
            }

            if (this.scrollingTimeout) {
                clearTimeout(this.scrollingTimeout);
            }

            if (!this.prefs().scrollToTop) {
                this.scrollingTimeout = setTimeout(() => this.onScrollEnd(), this.scrollingTimeoutValue);
            }

            this.isCallInNextFrameRequested = false;
        }

        /**
         * @description Carousel page click handler
         * @param {RefElement} el page element
         * @listens dom#click
         * @emits Carousel#pageclicked
         */
        onPageClick(el) {
            if (this.delta === 0 || this.prefs().scrollToTop) {
                /**
                 * @description Event to carousel page click
                 * @event Carousel#pageclicked
                 */
                this.emit('pageclicked', el.data('page'));
            }
        }

        /**
         * @description Scrolls active element into carousel viewport
         *
         * @returns {Carousel} carousel instance
         */
        scrollIntoView() {
            const elCarouselTrack = this.ref('elemCarouselTrack').get();

            if (!elCarouselTrack) {
                return this;
            }

            const elCurrentSlide = [].find.call(elCarouselTrack.children, (/** @type {HTMLElement} */element) => {
                return !element.dataset.cloned && element.classList.contains(this.prefs().pagerCurrentClass);
            });

            if (!elCurrentSlide) {
                return this;
            }

            if (this.isHorizontal()) {
                const offsetRight = elCurrentSlide.offsetLeft + elCurrentSlide.clientWidth;

                if (offsetRight > (elCarouselTrack.scrollLeft + elCarouselTrack.clientWidth)) {
                    this.scrollTo(0, offsetRight - elCarouselTrack.clientWidth);
                } else if (elCurrentSlide.offsetLeft < elCarouselTrack.scrollLeft) {
                    this.scrollTo(0, elCurrentSlide.offsetLeft);
                }
            } else {
                const offsetBottom = elCurrentSlide.offsetTop + elCurrentSlide.clientHeight;

                if (offsetBottom > (elCarouselTrack.scrollTop + elCarouselTrack.clientHeight)) {
                    this.scrollTo(offsetBottom - elCarouselTrack.clientHeight + elCurrentSlide.clientHeight, 0);
                } else if (elCurrentSlide.offsetTop < elCarouselTrack.scrollTop) {
                    this.scrollTo(elCurrentSlide.offsetTop - elCurrentSlide.clientHeight, 0);
                }
            }

            return this;
        }

        /**
         * @param {RefElement} el source of event
         */
        handlePaginationClick(el) {
            const page = el.data('page');
            const animate = el.data('animate');

            if (page !== null) {
                const pageIndex = parseInt(page + '', 10);

                if (animate) {
                    this.scrollToPage(parseInt(pageIndex + '', 10));
                } else {
                    this.scrollWithoutAnimate(parseInt(pageIndex + '', 10));
                }
            }
        }

        createPaginationElements() {
            const numberOfPages = this.getPagesCount();
            const pagination = new Array(numberOfPages).fill(0).map((_el, i) => ({ page: i, current: i === 0 }));

            this.render(undefined, { pagination }, this.ref('pagination')).then(() => {
                this.pagination = Promise.resolve(this.ref('pagination').get());
            });
        }

        onScroll() {
            this.scrolling = true;

            super.onScroll();
        }

        onScrollEnd() {
            this.scrolling = false;

            super.onScrollEnd();

            if (this.normalizeScrollPosition) {
                this.normalizeScrollPosition = false;
                this.scrollToSlide(this.getCurrentSlideIndex());
            }

            this.markVisibleElements();
        }

        /**
         * @description Mousedown event handler
         * @listens dom#mousedown
         * @param {RefElement} el source of event
         * @param {MouseEvent} event event instance in DOM
         * @returns {void}
         */
        onMouseDown(el, event) {
            this.allowMouseEvents = true;

            super.onMouseDown(el, event);
        }

        /**
         * @description Mouseup event handler
         * @listens dom#mouseup
         * @param {HTMLElement} _el HTMLElement
         * @param {Event} event event instance in DOM
         * @returns {void}
         */
        onMouseUp(_el, event) {
            if (
                !this.allowMouseEvents
                || (event.type === 'mouseleave' && event.currentTarget !== event.target)
            ) {
                return;
            }

            this.allowMouseEvents = false;

            const elemCarouselTrack = this.ref('elemCarouselTrack');

            if (this.mouseMoveDisposable) {
                this.mouseMoveDisposable.forEach(disposable => disposable());
                delete this.mouseMoveDisposable;
            }

            if (this.mouseLeaveDisposable) {
                this.mouseLeaveDisposable.forEach(disposable => disposable());
                delete this.mouseLeaveDisposable;
            }

            // we should remove scroll-snap-type with delay, otherwise it cause bouncing
            this.grabbingRemoveTimeout = setTimeout(() => {
                elemCarouselTrack.removeClass(this.prefs().grabbingClass);
            }, this.grabbingTimeoutValue);

            if (!this.delta) {
                this.delta = 0;
            }

            if (!this.deltaPrevPageSensitivity) {
                this.deltaPrevPageSensitivity = -50;
            }

            if (!this.deltaNextPageSensitivity) {
                this.deltaNextPageSensitivity = 50;
            }

            if (this.delta <= this.deltaPrevPageSensitivity) {
                this.scrollToPrevSlide();
            } else if (this.delta >= this.deltaNextPageSensitivity) {
                this.scrollToNextSlide();
            } else {
                this.scrollToSlide(this.getCurrentSlideIndex());
            }

            this.normalizeScrollPosition = true;
        }

        /**
         * @description Update carousel state
        */
        updateCarouselState() {
            if (!this.prefs().autoplayInfinite) {
                super.updateCarouselState();

                const ARIA_HIDDEN = 'aria-hidden';

                if (this.isScrollStart) {
                    this.ref(this.prefs().elemPrevButton).attr(ARIA_HIDDEN, 'true');
                } else {
                    this.ref(this.prefs().elemPrevButton).attr(ARIA_HIDDEN, false);
                }

                if (this.isScrollEnd) {
                    this.ref(this.prefs().elemNextButton).attr(ARIA_HIDDEN, 'true');
                } else {
                    this.ref(this.prefs().elemNextButton).attr(ARIA_HIDDEN, false);
                }
            }
        }

        isExternalSlidesAdded() {
            const currentProduct = document.getElementById('current-product');

            return currentProduct;
        }

        loadNewVideo(videoItem, event) {
            if (event?.target?.classList[0]?.includes('cta')) {
                event.preventDefault();
                event.stopImmediatePropagation();
                window.location = event.target.href;

                return;
            } else if (event?.target?.closest('.b-wishlist_button')) {
                return;
            }

            videoItem.addClass('m-current');

            var allItems = this.ref('elemCarouselTrack').get().children;
            var allItemsArray = Array.from(allItems);

            allItemsArray.forEach((item) => {
                if (item !== videoItem.get()) {
                    item.classList.remove('m-current');
                }
            });

            var videoLink = videoItem.data('videoId');

            if (videoLink.includes('tiktok')) {
                this.eventBus().emit('hideTiktokVideoBlock', false);
                this.eventBus().emit('hideVideoModule', true);
                this.eventBus().emit('addTiktokVideo', videoLink);
            } else {
                this.eventBus().emit('hideTiktokVideoBlock', true);
                this.eventBus().emit('hideVideoModule', false);
                this.eventBus().emit('videoChange', videoLink);
            }
        }

        markVisibleElements() {
            const elCarouselTrack = this.ref('elemCarouselTrack').get();

            if (!elCarouselTrack) {
                return this;
            }

            const carouselElements = this.ref('elemCarouselTrack').get().children;
            const trackRect = elCarouselTrack.getBoundingClientRect();

            for (let i = 0; i < carouselElements.length; i++) {
                const elementRect = carouselElements[i].getBoundingClientRect();
                const isVisible = (trackRect.left <= elementRect.left
                    && elementRect.left <= trackRect.right)
                    || (trackRect.left <= elementRect.right
                    && elementRect.right <= trackRect.right);

                if (isVisible) {
                    carouselElements[i].setAttribute('tile-visible', 'true');
                } else {
                    carouselElements[i].setAttribute('tile-visible', 'false');
                }
            }

            return this;
        }

        categoryTileWidth() {
            const titles = document.querySelectorAll('.js-category_name');
            const titles2 = document.querySelectorAll('.b-category_tile-subtitle strong');
            const items = titles.length ? titles : titles2;

            items?.forEach((item) => {
                item.closest('.b-category_tile-link').style.width = item.getBoundingClientRect().width + 'px';
            });
        }
    }

    return Carousel;
}
