import { elementInViewport, scrollWindowTo } from 'core/toolbox/scroll';
import { timeout } from 'widgets/toolbox/util';
import { isSmallView } from 'widgets/toolbox/viewtype';

/**
 * @typedef {ReturnType<typeof import('widgets/global/AccordionItem').default>} BasicAccordionItem
 */

/**
 * @description AccordionItem implementation
 * @param {BasicAccordionItem} BasicAccordionItem Base widget for extending
 * @returns {typeof AccordionItem} AccordionItem class
 */
export default function (BasicAccordionItem) {
    /**
     * @class AccordionItem
     * @augments BasicAccordionItem
     */
    class AccordionItem extends BasicAccordionItem {
        prefs() {
            return {
                scrollTimeout: 250,
                closeMobile: false,
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         */
        init() {
            this.isPanelOpen = this.prefs().expanded;
            this.isToggleAllowed = false;
            this.isMultipleSections = false;
            this.defineAttributes(this.prefs().expanded);
            this.onDestroy(this.clearAttributes.bind(this));
            this.onDestroy(this.cleanPanelHeight.bind(this));

            if (!this.prefs().expanded) {
                this.closePanel();
            }

            if (isSmallView() && this.prefs().closeMobile) {
                this.closePanel();
            }
        }

        /**
         * @description Toggle panel
         * @param {object} el - Button
         */
        togglePanel(el) {
            if (!this.isPanelOpen) {
                this.openPanel();
            } else if ((el && el.data('allowToggle')) || this.isToggleAllowed) {
                this.closePanel();
            }
        }

        /**
         * @description Open panel
         * @emits AccordionItem#closeallitems
         * @emits AccordionItem#openpanel
         */
        openPanel() {
            super.openPanel();

            this.has(this.prefs().accordionItemBtn, (accordionItemBtn) => {
                const accordionItemBtnEl = accordionItemBtn.get();

                this.onDestroy(timeout(() => {
                    if (!elementInViewport(accordionItemBtnEl, true)) {
                        scrollWindowTo(accordionItemBtnEl, true);
                    }
                }, this.prefs().scrollTimeout));
            });
        }
    }

    return AccordionItem;
}
