/* eslint-disable max-len, indent, sonarjs/no-duplicate-string */
/* sonar-ignore-start */

import WidgetsMgr from 'widgets/widgetsMgr';

import StickyHeader from 'core/global/StickyHeader';
import BasicInput from 'core/forms/BasicInput';
import BasicForm from 'core/forms/BasicForm';
import InputDate from 'core/forms/InputDate';
import InputSelect from 'core/forms/InputSelect';
import InputTextTypeNumber from 'core/forms/InputTextTypeNumber';
import InputTextarea from 'core/forms/InputTextarea';
import InputPassword from 'core/forms/InputPassword';
import InputTel from 'core/forms/InputTel';
import Button from 'core/global/Button';
import PreferredStoreSelector from 'core/storelocator/PreferredStoreSelector';
import StoreLocatorLink from 'core/global/StoreLocatorLink';
import Image from 'core/global/Image';
import VideoPlayer from 'core/global/VideoPlayer';
import VideoPlayerHTML5 from 'core/global/VideoPlayerHTML5';
import SearchBox from 'core/search/SearchBox';
import ProductSocialLinks from 'core/product/ProductSocialLinks';
import StickyElement from 'core/global/StickyElement';
import AccordionItem from 'core/global/AccordionItem';
import BackToTop from 'core/global/BackToTop';
import BaseAddToCartMixin from 'widgets/product/AddToCartMixin';
import AddToCartMixin from 'core/product/AddToCartMixin';
import ProductTile from 'core/product/ProductTile';
import GtmDataLayer from 'core/global/GtmDataLayer';
import Modal from 'core/global/Modal';
import Osano from 'core/global/Osano';
import ExponeaSegmentation from 'core/global/ExponeaSegmentation';
import ProcessButton from 'core/global/ProcessButton';
import JustAddedProductToCartMixin from 'core/product/JustAddedProductToCartMixin';
import JustAddedToCartModal from 'core/cart/JustAddedToCartModal';
import JustAddedToCartMgr from 'core/cart/JustAddedToCartMgr';
import BaseCartMgr from 'widgets/cart/CartMgr';
import CartMgr from 'core/cart/CartMgr';
import CartMixin from 'core/cart/CartMixin';
import CartLineItem from 'core/cart/CartLineItem';
import ProductSocialDisclosure from 'core/product/ProductSocialDisclosure';
import AddToWishlistMixin from 'plugin_wishlists/wishlist/AddToWishlistMixin';
import IdeasSearchForm from 'core/forms/IdeasSearchForm';
import EmailSubscribe from 'core/forms/ajax/EmailSubscribe';
import GlobalAlerts from 'core/global/GlobalAlerts';
import SearchList from 'core/search/SearchList';
import IdeasSearchList from 'core/search/IdeasSearchList';
import CategorySearchList from 'core/search/CategorySearchList';
import Carousel from 'core/global/Carousel';
import WishlistCarousel from 'core/global/WishlistCarousel';
import ContextLoader from 'core/global/ContextLoader';
import AccountHeaderLink from 'core/global/AccountHeaderLink';
import Noibu from 'core/global/Noibu';
import LinkedSubscription from 'core/product/LinkedSubscription';
import BreadCrumbName from 'core/global/BreadCrumbName';
import PoqWebCheckout from 'core/global/PoqWebCheckout';
import AccountDeleteForm from 'core/forms/AccountDeleteForm';
import Tooltip from 'core/global/Tooltip';
import HeroCarousel from 'core/global/HeroCarousel';

WidgetsMgr.addWidgetsList('global.core', () => [
    ['stickyHeader', StickyHeader, 'stickyHeader'],
    ['basicInput', BasicInput, 'basicInput'],
    ['basicForm', BasicForm, 'basicForm'],
    ['inputDate', InputDate, 'basicInput'],
    ['inputSelect', InputSelect, 'inputSelect'],
    ['inputTextTypeNumber', InputTextTypeNumber, 'inputText'],
    ['inputTextarea', InputTextarea, 'inputTextarea'],
    ['inputPassword', InputPassword, 'inputPassword'],
    ['inputTel', InputTel, 'inputTel'],
    ['button', Button, 'button'],
    ['processButton', ProcessButton, 'processButton'],
    ['preferredStoreSelector', PreferredStoreSelector],
    ['storeLocatorLink', StoreLocatorLink],
    ['accountHeaderLink', AccountHeaderLink],
    ['productTile', ProductTile, 'productTile'],
        ['productTile', BaseAddToCartMixin, 'productTile'],
        ['productTile', AddToCartMixin, 'productTile'],
        ['productTile', JustAddedProductToCartMixin, 'productTile'],
    ['image', Image],
    ['videoPlayer', VideoPlayer],
    ['videoPlayerHTML5', VideoPlayerHTML5],
    // Combobox
        ['searchBox', SearchBox, 'searchBox'],
    ['productSocialLinks', ProductSocialLinks],
    ['productSocialDisclosure', ProductSocialDisclosure, 'disclosure'],
    ['productSocialDisclosure', AddToWishlistMixin, 'productSocialDisclosure'],
    ['productSocialLinks', AddToWishlistMixin, 'productSocialLinks'],
    ['stickyElement', StickyElement],
    ['backtotop', BackToTop, 'backtotop'],
    ['accordionItem', AccordionItem, 'accordionItem'],
    ['osano', Osano],
    ['exponeaSegmentation', ExponeaSegmentation],
    ['gtmDataLayer', GtmDataLayer],
    ['modal', Modal, 'modal'],
    ['globalAlerts', GlobalAlerts, 'globalAlerts'],
    ['justAddedToCartModal', JustAddedToCartModal, 'modal'],
    ['cartLineItem', CartLineItem],
        ['cartLineItem', AddToWishlistMixin, 'cartLineItem'],
    ['cartMgr', BaseCartMgr],
        ['cartMgr', CartMgr, 'cartMgr'],
            ['cartMgr', CartMixin, 'cartMgr'],
                ['justAddedToCartMgr', JustAddedToCartMgr, 'cartMgr'],
    ['ideasSearchForm', IdeasSearchForm, 'form'],
    ['emailSubscribe', EmailSubscribe, 'emailSubscribe'],
    ['searchList', SearchList],
        ['ideasSearchList', IdeasSearchList, 'searchList'],
        ['categorySearchList', CategorySearchList, 'searchList'],
    ['carousel', Carousel, 'carousel'],
        ['wishlistCarousel', WishlistCarousel, 'carousel'],
    ['contextLoader', ContextLoader, 'contextLoader'],
    ['noibu', Noibu],
    ['poqWebCheckout', PoqWebCheckout],
    ['linkedSubscription', LinkedSubscription],
    ['breadCrumbName', BreadCrumbName],
    ['accountDeleteForm', AccountDeleteForm],
    ['tooltip', Tooltip],
    ['heroCarousel', HeroCarousel, 'heroCarousel']
]);

const hasContext = (ctx) => window.contexts.includes(ctx);

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('header'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'header.widgets' */'core/header/MegaMenu').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'core/header/MenuPanel').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'core/header/MenuBarItem').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'core/header/MenuBarItemExt').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'core/header/HamburgerMenuItem').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'core/cart/MinicartDialog').then(d => d.default),
        import(/* webpackChunkName: 'header.widgets' */'core/cart/Minicart').then(d => d.default)
    ]).then(deps => {
        const [
            MegaMenu,
            MenuPanel,
            MenuBarItem,
            MenuBarItemExt,
            HamburgerMenuItem,
            MinicartDialog,
            Minicart
        ] = deps;

        return {
            listId: 'header.core',
            widgetsDefinition: () => [
                ['megaMenu', MegaMenu, 'megaMenu'],
                ['menuBarItem', MenuBarItem, 'menuBarItem'],
                ['menuBarItemExt', MenuBarItemExt, 'menuBarItem'],
                ['hamburgerMenuItem', HamburgerMenuItem, 'hamburgerMenuItem'],
                ['menuPanel', MenuPanel, 'menuPanel'],
                ['minicartDialog', MinicartDialog, 'minicartDialog'],
                ['minicartDialog', CartMixin, 'minicartDialog'],
                ['minicart', Minicart, 'minicart']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('home'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'homepage.widgets' */ 'core/global/CuttableContent').then(d => d.default),
        import(/* webpackChunkName: 'homepage.widgets' */ 'core/global/TikTokVideo').then(d => d.default)
    ]).then(deps => {
        const [
            CuttableContent,
            TikTokVideo
        ] = deps;

        return {
            listId: 'home.core',
            widgetsDefinition: () => [
                ['cuttableContent', CuttableContent],
                ['tikTokVideo', TikTokVideo]
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('pdp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/ProductDetailContainer').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/ProductDetail').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/ProductImages').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/ColorSwatch').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/AlternativeImage').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/EProductProductDetail').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/EProductAddToCartMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/EProductJustAddedProductToCartMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/wishlist/EProductAddToWishlistMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/forms/ajax/EVoucherDataForm').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/Availability').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'plugin_storelocator/storelocator/StoreSearchForm').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'core/storelocator/SelectStoreSearchForm').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'plugin_storelocator/storelocator/SearchByPlace').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'core/storelocator/WoosMapSearchByPlace').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'plugin_storelocator/storelocator/StoreLocatorMgr').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'core/storelocator/WoosmapStoreLocatorMgr').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/forms/ajax/BackInStock').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/SetProductDetail').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/BuyTogetherProduct').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/ProductSet').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/BuyTogetherRecommendation').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/BuyTogetherCheckbox').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/AddAllToCartMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/RecentlyViewedMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/RecentlyViewedMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/account/ChangeWeakPasswordModal').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/account/ChangeWeakPasswordForm').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/product/DetailAddToCartMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/forms/QuantityInputStepperMixin').then(d => d.default),
        import(/* webpackChunkName: 'homepage.widgets' */ 'core/global/CuttableContent').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/PlusProductDetail').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/PlusProductAddToCartMixin').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/Specifications').then(d => d.default)

    ]).then(deps => {
        const [
            ProductDetailContainer,
            ProductDetail,
            ProductImages,
            ColorSwatch,
            AlternativeImage,
            EProductProductDetail,
            EProductAddToCartMixin,
            EProductJustAddedProductToCartMixin,
            EProductAddToWishlistMixin,
            EVoucherDataForm,
            Availability,
            StoreSearchForm,
            SelectStoreSearchForm,
            SearchByPlace,
            WoosMapSearchByPlace,
            StoreLocatorMgr,
            WoosmapStoreLocatorMgr,
            BackInStock,
            SetProductDetail,
            BuyTogetherProduct,
            ProductSet,
            BuyTogetherRecommendation,
            BuyTogetherCheckbox,
            AddAllToCartMixin,
            BaseRecentlyViewedMixin,
            RecentlyViewedMixin,
            ChangeWeakPasswordModal,
            ChangeWeakPasswordForm,
            DetailAddToCartMixin,
            QuantityInputStepperMixin,
            CuttableContent,
            PlusProductDetail,
            PlusProductAddToCartMixin,
            Specifications
        ] = deps;

        return {
            listId: 'product.core',
            widgetsDefinition: () => [
                ['productDetailContainer', ProductDetailContainer],
                ['inputStepper', QuantityInputStepperMixin, 'inputStepper'],
                ['productDetail', ProductDetail, 'productDetail'],
                    ['productDetail', AddToCartMixin, 'productDetail'],
                    ['productDetail', DetailAddToCartMixin, 'productDetail'],
                    ['productDetail', JustAddedProductToCartMixin, 'productDetail'],
                    ['productDetail', BaseRecentlyViewedMixin, 'productDetail'],
                    ['productDetail', RecentlyViewedMixin, 'productDetail'],
                        ['eProductProductDetail', EProductProductDetail, 'productDetail'],
                        ['eProductProductDetail', EProductAddToCartMixin, 'eProductProductDetail'],
                        ['eProductProductDetail', EProductAddToWishlistMixin, 'eProductProductDetail'],
                        ['eProductProductDetail', EProductJustAddedProductToCartMixin, 'eProductProductDetail'],
                        ['plusProductDetail', PlusProductDetail, 'productDetail'],
                        ['plusProductDetail', PlusProductAddToCartMixin, 'plusProductDetail'],
                ['availability', Availability],
                ['productImages', ProductImages, 'productImages'],
                ['colorSwatch', ColorSwatch, 'colorSwatch'],
                ['productTile', AlternativeImage, 'productTile'],
                ['eVoucherDataForm', EVoucherDataForm, 'ajaxform'],
                ['storeSearchForm', StoreSearchForm],
                    ['selectStoreSearchForm', SelectStoreSearchForm, 'storeSearchForm'],
                ['searchByPlace', SearchByPlace, 'inputText'],
                ['woosMapSearchByPlace', WoosMapSearchByPlace, 'inputText'],
                ['storeLocatorMgr', StoreLocatorMgr],
                    ['woosmapStoreLocatorMgr', WoosmapStoreLocatorMgr, 'storeLocatorMgr'],
                ['backInStock', BackInStock, 'backInStock'],
                ['setProductDetail', SetProductDetail, 'productDetail'],
                ['buyTogetherProduct', BuyTogetherProduct, 'setProductDetail'],
                ['productSet', ProductSet, 'productSet'],
                    ['productSet', AddAllToCartMixin, 'productSet'],
                        ['buyTogetherRecommendation', BuyTogetherRecommendation, 'productSet'],
                    ['productSet', RecentlyViewedMixin, 'productSet'],
                ['buyTogetherCheckbox', BuyTogetherCheckbox, 'inputCheckbox'],
                ['changeWeakPasswordModal', ChangeWeakPasswordModal, 'modal'],
                ['changeWeakPasswordForm', ChangeWeakPasswordForm, 'ajaxform'],
                ['cuttableContent', CuttableContent],
                ['specifications', Specifications]
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('orderconfirmation'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'orderconfirmation.widgets' */ 'core/checkout/ConfirmationContainer').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/account/PasswordResetForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/ajax/LoginForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'core/forms/ajax/LoginForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/ajax/CheckoutLoginForm').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/account/ChangeWeakPasswordMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/forms/ajax/OrderRegistrationForm').then(d => d.default)
    ]).then(deps => {
            const [
                ConfirmationContainer,
                PasswordResetForm,
                BaseLoginForm,
                LoginForm,
                ChangeWeakPasswordMixin,
                CheckoutLoginForm,
                OrderRegistrationForm
            ] = deps;

        return {
            listId: 'orderconfirmation.core',
            widgetsDefinition: () => [
                ['confirmationContainer', ConfirmationContainer],
                ['passwordResetForm', PasswordResetForm, 'ajaxform'],
                ['loginForm', BaseLoginForm, 'ajaxform'],
                    ['loginForm', LoginForm, 'loginForm'],
                        ['loginForm', ChangeWeakPasswordMixin, 'loginForm'],
                            ['checkoutLoginForm', CheckoutLoginForm, 'loginForm'],
                ['orderRegistrationForm', OrderRegistrationForm, 'ajaxform']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('plp'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'search.widgets' */ 'core/search/ProductListingMgr').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'core/search/RefinementsGridToggle').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'core/search/RefinementMenuItem').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'core/search/Refinement').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'core/search/RefinementsPanelToggle').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'core/search/RefinementsPanel').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/AlternativeImage').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/global/CuttableContent').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'core/search/QuickViewNavigation').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'core/search/StickyRefinements').then(d => d.default),
        import(/* webpackChunkName: 'search.widgets' */ 'core/search/LazyloadTrigger').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/ProductImages').then(d => d.default)
    ]).then(deps => {
        const [
            ProductListingMgr,
            RefinementsGridToggle,
            RefinementMenuItem,
            Refinement,
            RefinementsPanelToggle,
            RefinementsPanel,
            AlternativeImage,
            CuttableContent,
            QuickViewNavigation,
            StickyRefinements,
            LazyloadTrigger,
            ProductImages
        ] = deps;

        return {
            listId: 'search.core',
            widgetsDefinition: () => [
                ['cartMgr', CartMgr, 'cartMgr'],
                ['cartLineItem', CartLineItem],
                    ['cartLineItem', AddToWishlistMixin, 'cartLineItem'],
                // tabs
                ['productListingMgr', ProductListingMgr, 'productListingMgr'],
                ['refinementsPanelToggle', RefinementsPanelToggle, 'refinementsPanelToggle'],
                // refinements
                ['refinementsGridToggle', RefinementsGridToggle],
                ['refinementMenuItem', RefinementMenuItem, 'refinementMenuItem'],
                ['refinement', Refinement, 'refinement'],
                ['refinementsPanel', RefinementsPanel, 'refinementsPanel'],
                ['productTile', ProductTile, 'productTile'],
                    ['productTile', AlternativeImage, 'productTile'],
                ['cuttableContent', CuttableContent],
                ['quickViewNavigation', QuickViewNavigation],
                ['stickyRefinements', StickyRefinements],
                ['lazyloadTrigger', LazyloadTrigger],
                ['productImages', ProductImages]
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('cart'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'cart.widgets' */ 'core/cart/CartShippingTabs').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'core/forms/CouponForm').then(d => d.default),
        import(/* webpackChunkName: 'cart.widgets' */ 'core/cart/CheckoutButtons').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'plugin_storelocator/storelocator/StoreSearchForm').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'core/storelocator/SelectStoreSearchForm').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'plugin_storelocator/storelocator/SearchByPlace').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'core/storelocator/WoosMapSearchByPlace').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'plugin_storelocator/storelocator/StoreLocatorMgr').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'core/storelocator/WoosmapStoreLocatorMgr').then(d => d.default)
    ]).then(deps => {
        const [
            CartShippingTabs,
            CouponForm,
            CheckoutButtons,
            StoreSearchForm,
            SelectStoreSearchForm,
            SearchByPlace,
            WoosMapSearchByPlace,
            StoreLocatorMgr,
            WoosmapStoreLocatorMgr
        ] = deps;

        return {
            listId: 'cart.core',
            widgetsDefinition: () => [
                ['cartMgr', CartMgr, 'cartMgr'],
                    ['cartMgr', CartMixin, 'cartMgr'],
                ['checkoutButtons', CheckoutButtons, 'checkoutButtons'],
                // line items
                ['cartLineItem', CartLineItem],
                ['cartShippingTabs', CartShippingTabs, 'tabs'],
                ['couponform', CouponForm, 'couponform'],
                ['storeSearchForm', StoreSearchForm],
                    ['selectStoreSearchForm', SelectStoreSearchForm, 'storeSearchForm'],
                ['searchByPlace', SearchByPlace, 'inputText'],
                ['woosMapSearchByPlace', WoosMapSearchByPlace, 'inputText'],
                ['storeLocatorMgr', StoreLocatorMgr],
                    ['woosmapStoreLocatorMgr', WoosmapStoreLocatorMgr, 'storeLocatorMgr']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('account'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.core' */ 'core/account/ChangeWeakPasswordMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/forms/ajax/LoginForm').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/forms/ajax/ProfileForm').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/account/PasswordResetForm').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/account/PaymentsList').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/account/OrderHistoryMgr').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/forms/AutocompleteField').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/forms/WoosmapAutocompleteField').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/account/DownloadsMgr').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/forms/ajax/RefreshActivationLinkForm').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/account/AddressList').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/forms/AddressForm').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/account/ChangeWeakPasswordModal').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/account/ChangeWeakPasswordForm').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/account/BuyAgain').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/account/AccountGtmEvents').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/order/OrderProductSummary').then(d => d.default)
    ]).then(deps => {
        const [
            ChangeWeakPasswordMixin,
            LoginForm,
            ProfileForm,
            PasswordResetForm,
            PaymentsList,
            OrderHistoryMgr,
            AutocompleteField,
            WoosmapAutocompleteField,
            DownloadsMgr,
            RefreshActivationLinkForm,
            AddressList,
            AddressForm,
            ChangeWeakPasswordModal,
            ChangeWeakPasswordForm,
            BuyAgain,
            AccountGtmEvents,
            OrderProductSummary
        ] = deps;

        return {
            listId: 'account.core',
            widgetsDefinition: () => [
                ['loginForm', LoginForm, 'loginForm'],
                ['loginForm', ChangeWeakPasswordMixin, 'loginForm'],
                ['profileForm', ProfileForm, 'ajaxform'],
                ['passwordResetForm', PasswordResetForm, 'passwordResetForm'],
                ['paymentsList', PaymentsList, 'paymentsList'],
                ['orderHistoryMgr', OrderHistoryMgr, 'orderHistoryMgr'],
                // inputText
                ['autocompleteField', AutocompleteField, 'inputText'],
                    ['woosmapAutocompleteField', WoosmapAutocompleteField, 'autocompleteField'],
                ['downloadsMgr', DownloadsMgr],
                ['refreshActivationLinkForm', RefreshActivationLinkForm, 'ajaxform'],
                ['addressList', AddressList, 'addressList'],
                ['addressForm', AddressForm, 'addressForm'],
                ['changeWeakPasswordModal', ChangeWeakPasswordModal, 'modal'],
                ['changeWeakPasswordForm', ChangeWeakPasswordForm, 'ajaxform'],
                ['buyAgain', BuyAgain],
                ['accountGtmEvents', AccountGtmEvents],
                ['orderProductSummary', OrderProductSummary, 'orderProductSummary']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.widgets' */ 'core/checkout/CheckoutMgr').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'core/checkout/SummaryStepMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'core/checkout/CheckoutStepMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'core/checkout/ShippingStepMixin').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'core/checkout/BillingForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'core/checkout/ShippingForm').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/forms/AutocompleteField').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/forms/WoosmapAutocompleteField').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'core/checkout/PaymentAccordion').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'core/checkout/PaymentAccordionItem').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'core/checkout/PaymentAccordionItemGiftCard').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'core/checkout/AddressCheckoutForm').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/account/ChangeWeakPasswordModal').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/account/ChangeWeakPasswordForm').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/account/ChangeWeakPasswordMixin').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/forms/AddressForm').then(d => d.default),
        import(/* webpackChunkName: 'account.core' */ 'core/forms/ajax/LoginForm').then(d => d.default),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/ajax/CheckoutLoginForm').then(d => d.default)
    ]).then(deps => {
        const [
            CheckoutMgr,
            SummaryStepMixin,
            CheckoutStepMixin,
            ShippingStepMixin,
            BillingForm,
            ShippingForm,
            AutocompleteField,
            WoosmapAutocompleteField,
            PaymentAccordion,
            PaymentAccordionItem,
            PaymentAccordionItemGiftCard,
            AddressCheckoutForm,
            ChangeWeakPasswordModal,
            ChangeWeakPasswordForm,
            ChangeWeakPasswordMixin,
            AddressForm,
            LoginForm,
            CheckoutLoginForm
        ] = deps;

        return {
            listId: 'checkout.core',
            widgetsDefinition: () => [
                ['checkoutMgr', CheckoutMgr, 'checkoutMgr'],
                ['checkoutMgr', CheckoutStepMixin, 'checkoutMgr'],
                ['checkoutMgr', SummaryStepMixin, 'checkoutMgr'],
                ['checkoutMgr', ShippingStepMixin, 'checkoutMgr'],
                ['addressCheckoutForm', AddressCheckoutForm, 'addressCheckoutForm'],
                // addressForm > addressCheckoutForm
                ['billingForm', BillingForm, 'billingForm'],
                ['shippingForm', ShippingForm, 'shippingForm'],
                ['autocompleteField', AutocompleteField, 'inputText'],
                ['woosmapAutocompleteField', WoosmapAutocompleteField, 'autocompleteField'],
                ['paymentAccordion', PaymentAccordion, 'paymentAccordion'],
                ['paymentAccordionItem', PaymentAccordionItem, 'paymentAccordionItem'],
                ['paymentAccordionItemGiftCard', PaymentAccordionItemGiftCard, 'paymentAccordionItem'],
                ['changeWeakPasswordModal', ChangeWeakPasswordModal, 'modal'],
                ['changeWeakPasswordForm', ChangeWeakPasswordForm, 'ajaxform'],
                ['addressForm', AddressForm, 'addressForm'],
                ['loginForm', LoginForm, 'loginForm'],
                    ['loginForm', ChangeWeakPasswordMixin, 'loginForm'],
                        ['checkoutLoginForm', CheckoutLoginForm, 'loginForm']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('storelocator'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'storelocator.widgets' */ 'core/storelocator/SelectStoreSearchForm').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'core/storelocator/WoosMapSearchByPlace').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'core/storelocator/WoosmapStoreLocatorMgr').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'core/storelocator/WoosMapLatLngDistance').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'core/storelocator/WoosmapStoreDirections').then(d => d.default),
        import(/* webpackChunkName: 'storelocator.widgets' */ 'core/account/TwitterFeeds').then(d => d.default),
        import(/* webpackChunkName: 'homepage.widgets' */ 'core/global/CuttableContent').then(d => d.default)
    ]).then(deps => {
        const [
            SelectStoreSearchForm,
            WoosMapSearchByPlace,
            WoosmapStoreLocatorMgr,
            WoosMapLatLngDistance,
            WoosmapStoreDirections,
            TwitterFeeds,
            CuttableContent
        ] = deps;

        return {
            listId: 'storelocator.core',
            widgetsDefinition: () => [
                ['selectStoreSearchForm', SelectStoreSearchForm, 'storeSearchForm'],
                ['woosMapSearchByPlace', WoosMapSearchByPlace, 'inputText'],
                ['woosmapStoreLocatorMgr', WoosmapStoreLocatorMgr, 'storeLocatorMgr'],
                ['woosMapLatLngDistance', WoosMapLatLngDistance, 'latLngDistance'],
                ['woosmapStoreDirections', WoosmapStoreDirections],
                ['twitterFeeds', TwitterFeeds],
                ['cuttableContent', CuttableContent]
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('wishlist'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'wishlist.wishlist' */'core/wishlist/ExtendedWishlistMgr').then(d => d.default),
        import(/* webpackChunkName: 'wishlist.wishlist' */'core/wishlist/WishlistExpirationMsg').then(d => d.default),
        import(/* webpackChunkName: 'wishlist.wishlist' */'core/wishlist/WishlistIdeasToggler').then(d => d.default),
        import(/* webpackChunkName: 'wishlist.wishlist' */'core/wishlist/ExtendedWishlistItem').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/AlternativeImage').then(d => d.default),
        import(/* webpackChunkName: 'product.widgets' */ 'core/product/AlternativeImage').then(d => d.default),
        import(/* webpackChunkName: 'wishlist.wishlist' */'core/wishlist/ShowCustomerWishlistMixin').then(d => d.default)
    ]).then(deps => {
        const [
            ExtendedWishlistMgr,
            WishlistExpirationMsg,
            WishlistIdeasToggler,
            ExtendedWishlistItem,
            WidgetsAlternativeImage,
            CoreAlternativeImage,
            ShowCustomerWishlistMixin
        ] = deps;

        return {
            listId: 'wishlist.wishlist.core',
            widgetsDefinition: () => [
                ['extendedWishlistMgr', ExtendedWishlistMgr, 'wishlistMgr'],
                ['wishlistExpirationMsg', WishlistExpirationMsg],
                ['wishlistIdeasToggler', WishlistIdeasToggler],
                ['extendedWishListItem', ExtendedWishlistItem, 'wishListItem'],
                    ['extendedWishListItem', WidgetsAlternativeImage, 'extendedWishListItem'],
                    ['extendedWishListItem', CoreAlternativeImage, 'extendedWishListItem'],
                    ['extendedWishListItem', AddToCartMixin, 'extendedWishListItem'],
                ['productTile', AddToWishlistMixin, 'productTile'],
                ['productTile', ShowCustomerWishlistMixin, 'productTile']
            ]
        };
    })
});

WidgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('cart') && hasContext('applepay'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.applepay' */ 'core/cart/ApplePayButtonContainer').then(d => d.default)
    ]).then(deps => {
        const [
            ApplePayButtonContainer
        ] = deps;

        return {
            listId: 'cart.applepay',
            widgetsDefinition: () => [
                ['applePayButtonContainer', ApplePayButtonContainer]
            ]
        };
    })
});
