import 'widgets/widgetsList';
import 'core/widgetsList';
import WidgetsMgr from 'widgets/widgetsMgr';

/*
Don't delete next comment, it's a placeholder for additional imports
See details in documentation/tools/WebpackConfiguration.md
 */
import 'int_paypal/paypalWidgetLists.js';
import 'int_adyen_SFRA/adyenWidgetLists.js';
import 'plugin_wishlists_w/wishlistWidgetsList.ts';
import 'plugin_storelocator_w/widgetsList.ts';
import 'plugin_serviceworker_w/sw-registration.ts';
import 'app_page_designer_w/pageDesignerWidgetsList.ts';
import 'int_gtm_w/clientGTM.ts';
import 'int_globale_sfra/globaleWidgetLists.js';
import 'int_trg/trgWidgetLists.js';
import 'int_pcipal/pcipalWidgetLists.js';
// Globally available tools
import { init } from 'core/toolbox/init';
import { submitFormJson } from 'widgets/toolbox/ajax';
import { exposeMethodGlobally } from 'core/toolbox/util';

// run global entry point
init();

// expose AJAX for global usage (for ex. in case of AJAX-requests in legacy 3-d party scripts)
exposeMethodGlobally('ajax', submitFormJson);

WidgetsMgr.run();

// @ts-ignore
if (module.hot) {
    // @ts-ignore
    module.hot.accept(['widgets/widgetsList', 'core/widgetsList'], () => WidgetsMgr.restartWidgets());
}
