/* eslint-disable max-len */
/* eslint-disable indent */
const hasContext = (/** @type {string} */ctx) => window.contexts.includes(ctx);

import widgetsMgr from 'widgets/widgetsMgr';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('account'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'account.adyen' */ './widgets/account/AdyenAddPaymentMethodForm').then(d => d.default)
    ]).then(deps => {
        const [
            AdyenAddPaymentMethodForm
        ] = deps;

        return {
            listId: 'account.payment.adyen',
            widgetsDefinition: () => [
                ['adyenAddPaymentMethodForm', AdyenAddPaymentMethodForm, 'addPaymentMethodForm']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.adyen' */ './widgets/checkout/AdyenPaymentAccordion').then(d => d.default),
        import(/* webpackChunkName: 'checkout.adyen' */ './widgets/checkout/PaymentAdyenItem').then(d => d.default),
        import(/* webpackChunkName: 'checkout.adyen' */ './widgets/checkout/PaymentAccordionItemAdyenComponent').then(d => d.default)
    ]).then(deps => {
        const [
            AdyenPaymentAccordion,
            PaymentAdyenItem,
            PaymentAccordionItemAdyenComponent
        ] = deps;

        return {
            listId: 'checkout.adyen',
            widgetsDefinition: () => [
                // accordion / paymentAccordion
                    ['paymentAccordion', AdyenPaymentAccordion, 'paymentAccordion'],
                    ['paymentAdyenItem', PaymentAdyenItem, 'accordionItem'],
                // accordionItems / paymentAccordionItem
                    ['paymentAccordionItemAdyenComponent', PaymentAccordionItemAdyenComponent, 'paymentAccordionItem']
            ]
        };
    })
});


widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('orderconfirmation'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'orderconfirmation.widgets' */ 'widgets/checkout/RadioSelector').then(d => d.default),
        import(/* webpackChunkName: 'orderconfirmation.widgets' */ './widgets/forms/ajax/AdyenCharityForm').then(d => d.default)
    ]).then(deps => {
            const [
                RadioSelector,
                AdyenCharityForm
            ] = deps;

        return {
            listId: 'orderconfirmation.adyen',
            widgetsDefinition: () => [
                ['adyenCharityForm', AdyenCharityForm, 'ajaxform'],
                ['radioSelector', RadioSelector, 'inputRadio']
            ]
        };
    })
});
